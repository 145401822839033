/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu pb--08 pl--40 pr--40 pt--08" menu={true} name={"menu"}>
          
          <Menu className="pb--08 pt--08" fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Image className="--shape2 --left" src={"https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=660x_.png"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=350x_.png 350w, https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=660x_.png 660w, https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--400 pr--10" href={"#sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--400 pr--10" href={"#reference"} target={""} content={"Reference"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 fs--18 w--400 pr--10" href={"#sluzby"} target={""} content={"Informace"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5 fs--18 w--400" href={"#kontakt-2"} target={""} content={"Kontaktovat"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-uzpbw3 bg--left bg--bottom --full" name={"uvod"} style={{"backgroundColor":"rgba(0,0,0,1)"}} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 pb--40 flex--bottom" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 w--400 title-box--invert lh--1" style={{"maxWidth":1044}} content={"<span style=\"font-weight: bold; color: rgb(208, 2, 27);\">Střechy Kettner</span><br>"}>
              </Title>

              <Text className="text-box fs--30 text-box--invert ls--02 lh--16 mt--20" style={{"maxWidth":854}} content={"<span style=\"font-weight: bold;\"><span style=\"background-color: rgb(155, 155, 155);\">Klempířství, pokrývačství, tesařství, izolatérství, svařování folií a stavební práce. <br>Jsme certifikovaným partnerem společnosti VELUX <br>a poskytujeme odbornou montáž střešních oken.</span><br><br></span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5" href={"#kontakt-2"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--20 pt--20 flex--center" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/obecne/88e67c255f7d45fbadefead760a44b9f.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--500" content={"Pokrývačské a stavební práce"}>
              </Title>

              <Text className="text-box" content={"Pokládka všech druhů krytin, natažení folie, hydroizolace spodních staveb proti zemní vlhkosti a netlakové vodě, a také proti radonu\nlaťování, tepelná izolace jak na střechy tak na podlahy.&nbsp;<br>Stavební činnost: provádíme stavby na klíč od kopnutí až po dokončení. (rodinné domy a garáže)<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/kvetinarstvi/1c76659180bd4159b6d9b4804db35767.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--400" content={"Tesařské práce"}>
              </Title>

              <Text className="text-box" content={"Montáž krovů, vazníku, pergol, dřevěné přístřešky,\ndemontáž střech, podbití podhledů, atik a montáž dřevěných  podlah&nbsp;<br><br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":300}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/1bf57d65067e4fcd82d8e6c77685617b.svg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Title className="title-box w--400 ls--002" content={"Klempířské práce a montáž střešních oken"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Montáž okapů a falcovaných krytin, veškeré klempířské práce parapety, římsy vše kolem střechy.<br>Střešní okna: Jsme certifikovaným partnerem společnosti Velux. (Velux Team), dále provádíme certifikovanou montáž střešních oken všech druhů (Roto, Fakro a Dakea)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17612/3d95feb8b35b4208bf56fb92ff3efe0b_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/3d95feb8b35b4208bf56fb92ff3efe0b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/3d95feb8b35b4208bf56fb92ff3efe0b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/3d95feb8b35b4208bf56fb92ff3efe0b_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17612/da557aeca02a4d7394594d1c81671f58_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/da557aeca02a4d7394594d1c81671f58_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/da557aeca02a4d7394594d1c81671f58_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/da557aeca02a4d7394594d1c81671f58_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"reference"} style={{"paddingTop":null,"backgroundColor":"var(--color-variable-1--darken)"}}>
          
          <ColumnWrap className="column__flex --center el--1 pb--60 pt--60" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 w--400 title-box--invert" content={"<span style=\"text-decoration-line: underline;\">Reference</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5 fs--20" href={"#kontakt-2"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"reference-2"} style={{"backgroundColor":"rgba(232, 239, 234, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--3 mt--0 flex--top" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/0f10c3c169694779ad6b3ffb84f3c8b5_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/0f10c3c169694779ad6b3ffb84f3c8b5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/0f10c3c169694779ad6b3ffb84f3c8b5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/0f10c3c169694779ad6b3ffb84f3c8b5_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Střecha komplet"}>
              </Title>

              <Text className="text-box" content={"Ústí nad labem, mateřská škola"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/055286e9a49f4782a964125fe3f75442_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/055286e9a49f4782a964125fe3f75442_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/055286e9a49f4782a964125fe3f75442_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/055286e9a49f4782a964125fe3f75442_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Klempířské práce&nbsp;"}>
              </Title>

              <Text className="text-box" content={"Rezidence Hradešínská Praha"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/e56b5a3d0d0b4c5dbc0e56b0a83d5da1_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/e56b5a3d0d0b4c5dbc0e56b0a83d5da1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/e56b5a3d0d0b4c5dbc0e56b0a83d5da1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/e56b5a3d0d0b4c5dbc0e56b0a83d5da1_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Střešní okna na klíč"}>
              </Title>

              <Text className="text-box" content={"Oka VELUX,&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/d703882bfee74e3d8ae21f0c0aae0c59_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/d703882bfee74e3d8ae21f0c0aae0c59_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/d703882bfee74e3d8ae21f0c0aae0c59_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/d703882bfee74e3d8ae21f0c0aae0c59_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Stání pro automobil"}>
              </Title>

              <Text className="text-box" content={"Mělník"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/fbfd9c1a299247468fe75531603f07ec_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Stavba garáže na klíč"}>
              </Title>

              <Text className="text-box" content={"Mělník"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Plechová krytina"}>
              </Title>

              <Text className="text-box" content={"Praha"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/3707e1a2b5374cb59be0d45618959635_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Pergola na klíč"}>
              </Title>

              <Text className="text-box" content={"Mělník"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/884639a4a9094dbf99ad2fbdb87698b9_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/884639a4a9094dbf99ad2fbdb87698b9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/884639a4a9094dbf99ad2fbdb87698b9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/884639a4a9094dbf99ad2fbdb87698b9_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box mt--06" content={"Izolace"}>
              </Title>

              <Text className="text-box" content={"Mělník"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--08 pb--20 pl--10 pr--10 pt--10" style={{"maxWidth":600,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/17612/49b4ee54cffd48b78aac72c36ad1597d_s=660x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/17612/49b4ee54cffd48b78aac72c36ad1597d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17612/49b4ee54cffd48b78aac72c36ad1597d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17612/49b4ee54cffd48b78aac72c36ad1597d_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box title-box--left mt--06" style={{"maxWidth":367}} content={"Klempířina kruhová"}>
              </Title>

              <Text className="text-box" content={"Praha"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt"} style={{"backgroundColor":"var(--color-variable-1--darken)"}}>
          
          <ColumnWrap className="column__flex el--2 pb--60 pt--60" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 title-box--invert" content={"<span style=\"text-decoration-line: underline;\">Střechy Kettner nabízíme kompletní řešení pro vaši stavbu.</span>"}>
              </Title>

              <Text className="text-box fs--20 text-box--invert ls--0 lh--16" content={"Jsme certifikovaná a spolehlivá firma se zárukou kvality která má dlouholetou tradicí.<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5" href={"#kontakt-2"} content={"Kontaktovat"}>
              </Button>

              <Title className="title-box" content={"<span style=\"color: rgb(255, 255, 255);\">Spolupracujeme a doporučujeme:</span>"}>
              </Title>

              <Text className="text-box pl--40" content={"<a href=\"https://www.zlana.cz/\" target=\"_blank\" style=\"color: rgb(255, 255, 255); font-weight: bold;\">Z lana.cz&nbsp; - výškové práce<br>https://www.zlana.cz/</a><br>"}>
              </Text>

              <Text className="text-box pl--40" content={"<a href=\"https://www.velux.cz/\" target=\"_blank\" style=\"font-weight: bold; color: rgb(255, 255, 255);\">Velux - střešní okna<br>https://www.velux.cz/</a><br>"}>
              </Text>

              <Text className="text-box text-box--left pl--40" content={"<a href=\"https://www.dachdecker.cz/\" target=\"_blank\" style=\"font-weight: bold; color: rgb(255, 255, 255);\">Deg Dachdecker - vše pro vaši střechu<br>https://www.dachdecker.cz/</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--20 pt--50" style={{"maxWidth":758}}>
              
              <Text className="text-box text-box--center fs--26 w--500 text-box--invert lh--16 pb--02" style={{"maxWidth":686}} content={"Vše s dodávkou veškerého potřebného materiálu.<br>Zdarma cenová nabídka a konzultace na místě stavby<br><br><br><br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/17612/95dc35bd479044bdb9be39bd99ba674a_s=660x_.PNG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17612/95dc35bd479044bdb9be39bd99ba674a_s=350x_.PNG 350w, https://cdn.swbpg.com/t/17612/95dc35bd479044bdb9be39bd99ba674a_s=660x_.PNG 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-qxkapp bg--center pb--0 pl--16 pr--16 pt--0" name={"kontakt-2"} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17612/ff3e9d816b254c8c819c55f2519d1835_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--25 pt--25" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--48 title-box--invert" content={"<span style=\"font-weight: bold;\">Kontaktujte nás pro nezávaznou konzultaci<br></span><a href=\"+420724284942\">+420 724 284 942</a><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"}>
          
          <ColumnWrap className="column__flex el--4 mb--60 mt--60" columns={"4"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=660x_.png"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=350x_.png 350w, https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=660x_.png 660w, https://cdn.swbpg.com/t/17612/2488aa36dd0e4c70bf2d516301a35395_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box fs--22" content={"<span style=\"text-decoration-line: underline; font-weight: bold; color: rgb(0, 0, 0);\">kettnerjosef@seznam.cz</span><a href=\"kettnerjosef@seznam.cz \" target=\"_blank\" style=\"font-weight: bold;\"><br></a>"}>
              </Text>

              <Text className="text-box fs--22" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(0, 0, 0); text-decoration-line: underline;\">+420 724 284</span><a href=\"724284942\" target=\"_blank\"> 942</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontakt"} maxWidth={"180"}>
              </Title>

              <Text className="text-box fs--16 lh--16" content={"Josef Kettner<br>IČO:86737961<br>Jelenice 97<br>Malý Újezd<br>&nbsp;27731<br>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Navigace"} maxWidth={"240"}>
              </Title>

              <Text className="text-box fs--16 lh--16" content={"<a href='#[[T75SecName0]]'>Zpět nahoru</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href='#[[T75SecName2]]'>Služby</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href='#[[T75SecName5]]'>Reference</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href='#[[T75SecName6]]'>Informace</a>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box text-box--left fs--26 lh--14" style={{"color":"#000;"}} content={"<span style=\"text-decoration-line: underline; font-weight: bold;\"><a href=\"https://www.facebook.com/St%C5%99echy-komplet-Kettner-100795938374873/?ref=page_internal\" target=\"_blank\">facebook</a></span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/17612/8ec469f435894b1cabcc26f49ed4961b_s=660x_.PNG"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17612/8ec469f435894b1cabcc26f49ed4961b_s=350x_.PNG 350w, https://cdn.swbpg.com/t/17612/8ec469f435894b1cabcc26f49ed4961b_s=660x_.PNG 660w, https://cdn.swbpg.com/t/17612/8ec469f435894b1cabcc26f49ed4961b_s=860x_.PNG 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}